/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"

import Header from "./header"

import "../styles/index.scss"
import "@fortawesome/fontawesome-free/css/all.css"
import logoNewpricer from "../images/logo_podium.png"


const Layout = ({ children, navbarScrollOpacity }) => (
  <>
    <Header navbarScrollOpacity={navbarScrollOpacity} />

    <main
      className={classNames("min-height-100vh-3_5rem", {
        "pt-3_5rem": !navbarScrollOpacity,
      })}
    >
      {children}
    </main>

    <footer className="navbar navbar-dark foot py-5">
      <div className="container">
        <div class="text-center">
          <div class="d-flex">
            <img src={logoNewpricer} alt="Logo Newpricer" height="40"></img>
              <h3 className="h3 font-weight-lighter text-danger pl-4">
                    n e w p r i c e r
              </h3>
          </div>
          <div className="navbar-text text-white">
            newpricer © {new Date().getFullYear()}
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-around">
        <Link to="/legal/" className="text-white mx-3">
          Legal notice
        </Link>
        <p className="text-white mx-3">|</p>
          <Link to="/privacy/" className="text-white mx-3">
            Privacy
          </Link>

        </div>
      </div>
    </footer>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
