
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import logoNewpricer from "../images/logo_podium.png"
import logoAtpco from "../images/references/ATPCO_partner.png"
import CalendlyRed from "../components/CalendlyRed"



const Header = ({ navbarScrollOpacity }) => {
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    function handleNavbarScrollOpacity() {
      setOpacity(
        Math.max(0, Math.min(0.95, window.pageYOffset / window.innerHeight))
      )
    }

    if (navbarScrollOpacity) {
      handleNavbarScrollOpacity()
      window.addEventListener("scroll", handleNavbarScrollOpacity)
    } else {
      setOpacity(1)
    }

    return () => {
      if (navbarScrollOpacity) {
        window.removeEventListener("scroll", handleNavbarScrollOpacity)
      }
    }
  }, [navbarScrollOpacity])

  return (
    <header>
      <nav id="navbar" className="navbar fixed-top navbar-light bg-white" style={{ opacity: opacity }}>

        <div className="container">
          <div class="col justify-content-start d-inline-flex align-items-middle">
            <a class=" navbar-brand" href="/">
              <img src={logoNewpricer} alt="logo_newpricer" height="40" />
            </a>
            <a class="navbar-brand" href="/">
              <h3 className="h3 font-weight-lighter text-danger text-center">
                    n e w p r i c e r
              </h3>
            </a>
          </div>
          {/*
          <div className="col-3 d-flex text-center justify-content-center align-items-center us">
            < CalendlyRed />
          </div> */}
          <div class="col text-right">
            <img class="logoraw" src={logoAtpco} alt="ATPCO"></img>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
